// function grabItems(el, cat) {
//     return JSON.stringify(Array.from(el.querySelectorAll('li')).map(li => { const name = li.textContent.replace('→ ', ''); return {name: name, tag: `${cat}:${name.toLowerCase().replace(/\s(-\s)?/gi, '-')}`};}), null, 2);
// }

export default [
  {
    name: "Seating",
    tag: "seating",
    pingpong: true,
    subcategories: [
      {
        name: "Chair - Dining",
        tag: "seating:chair-dining"
      },
      {
        name: "Chair - Office",
        tag: "seating:chair-office"
      },
      {
        name: "Chair - Professional",
        tag: "seating:chair-professional"
      },
      {
        name: "Sofa and Couch",
        tag: "seating:sofa-and-couch"
      },
      {
        name: "Bench",
        tag: "seating:bench"
      },
      {
        name: "Chair - Lounge",
        tag: "seating:chair-lounge"
      },
      {
        name: "Stools",
        tag: "seating:stools"
      },
      {
        name: "Chair - Folding",
        tag: "seating:chair-folding"
      },
      {
        name: "Chair - Kids",
        tag: "seating:chair-kids"
      },
      {
        name: "Stools - Bar",
        tag: "seating:stools-bar"
      }
    ]
  },
  {
    name: "Kitchen",
    tag: "kitchen",
    pingpong: true,
    subcategories: [
      {
        name: "Food",
        tag: "kitchen:food"
      },
      {
        name: "Trays",
        tag: "kitchen:trays"
      },
      {
        name: "Kitchen - Cookware",
        tag: "kitchen:kitchen-cookware"
      },
      {
        name: "Kitchen - Dishes and cutlery",
        tag: "kitchen:dishes-and-cutlery"
      }
    ]
  },
  {
    name: "Bathroom",
    tag: "bathroom",
    pingpong: false,
    subcategories: []
  },
  {
    name: "Tables",
    tag: "tables",
    pingpong: true,
    subcategories: [
      {
        name: "Dining",
        tag: "tables:dining"
      },
      {
        name: "Coffee",
        tag: "tables:coffee"
      },
      {
        name: "Kitchen",
        tag: "tables:kitchen"
      },
      {
        name: "Console",
        tag: "tables:console"
      },
      {
        name: "Office",
        tag: "tables:office"
      },
      {
        name: "Nightstands",
        tag: "tables:nightstands"
      }
    ]
  },
  {
    name: "Standing Furniture - Case Goods",
    tag: "standing-furniture-case-goods",
    pingpong: true,
    subcategories: [
      {
        name: "Standing Cabinets",
        tag: "standing-furniture-case-goods:standing-cabinets"
      },
      {
        name: "Standing Armoires",
        tag: "standing-furniture-case-goods:standing-armoires"
      },
      {
        name: "Secretaries",
        tag: "standing-furniture-case-goods:secretaries"
      },
      {
        name: "Standing Kitchen Cabinets",
        tag: "standing-furniture-case-goods:standing-kitchen-cabinets"
      },
      {
        name: "Standing Bookshelves",
        tag: "standing-furniture-case-goods:standing-bookshelves"
      },
      {
        name: "Coat Rack",
        tag: "standing-furniture-case-goods:coat-rack"
      },
      {
        name: "Floor Racks",
        tag: "standing-furniture-case-goods:floor-racks"
      },
      {
        name: "Standing Mirrors",
        tag: "standing-furniture-case-goods:standing-mirrors"
      },
      {
        name: "Furniture - Miscellaneous",
        tag: "standing-furniture-case-goods:furniture-miscellaneous"
      }
    ]
  },
  {
    name: "School",
    tag: "school",
    pingpong: true,
    subcategories: [
      {
        name: "School Furniture ",
        tag: "school:furniture"
      },
      {
        name: "School Bags ",
        tag: "school:bags"
      },
      {
        name: "Documents and panels",
        tag: "school:documents and panels"
      },
    ]
  },
  {
    name: "Lighting",
    tag: "lighting",
    pingpong: true,
    subcategories: [
      {
        name: "Lamp - Shades",
        tag: "lighting:lamp-shades"
      },
      {
        name: "Lamps - Table",
        tag: "lighting:lamps-table"
      },
      {
        name: "Sconces/Wall Brackets",
        tag: "lighting:sconces-wall-brackets"
      },
      {
        name: "Lamps - Floor",
        tag: "lighting:lamps-floor"
      },
      {
        name: "Hanging Pendants and Lights",
        tag: "lighting:hanging-pendants-and-lights"
      },
      {
        name: "Lamps - Desk",
        tag: "lighting:lamps-desk"
      },
      {
        name: "Industrial/Commercial Lighting",
        tag: "lighting:industrial-commercial-lighting"
      },
      {
        name: "Signs - Standing",
        tag: "lighting:signs-standing"
      }
    ]
  },
  {
    name: "Art",
    tag: "art",
    pingpong: true,
    subcategories: [
      {
        name: "Paintings",
        tag: "art:paintings"
      },
      {
        name: "Art Related - Miscellaneous",
        tag: "art:art-related-miscellaneous"
      },
      {
        name: "Animals",
        tag: "art:animals"
      },
      {
        name: "Sculpture",
        tag: "art:sculpture"
      },
      {
        name: "Posters",
        tag: "art:posters"
      },
      {
        name: "Tribal",
        tag: "art:tribal"
      },
      {
        name: "Photographs",
        tag: "art:photographs"
      }
    ]
  },
  {
    name: "Rugs",
    tag: "rugs",
    pingpong: true,
    subcategories: [ ]
  },
  {
    name: "Copyright Free",
    tag: "copyright-free",
    pingpong: false,
    subcategories: [ ]
  },
  {
    name: "Accessories",
    tag: "accessories",
    pingpong: true,
    subcategories: [
      {
        name: "Accessories - Miscellaneous",
        tag: "accessories:accessories-miscellaneous"
      },
      {
        name: "Clocks & alarms",
        tag: "accessories:clocks-alarms"
      },
      {
        name: "Jars",
        tag: "accessories:jars"
      },
      {
        name: "Figures & Figurines",
        tag: "accessories:figures-&-figurines"
      },
      {
        name: "Boxes",
        tag: "accessories:boxes"
      },
      {
        name: "Weapons",
        tag: "accessories:weapons"
      },
      {
        name: "Candlesticks",
        tag: "accessories:candlesticks"
      },
      {
        name: "Books",
        tag: "accessories:books"
      },
      {
        name: "Toys",
        tag: "accessories:toys"
      },
      {
        name: "Ashtrays",
        tag: "accessories:ashtrays"
      },
      {
        name: "Baskets",
        tag: "accessories:baskets"
      },
      {
        name: "Trophies",
        tag: "accessories:trophies"
      },
      {
        name: "Wastebaskets",
        tag: "accessories:wastebaskets"
      },
      {
        name: "Cleaning",
        tag: "accessories:cleaning"
      },
      {
        name: "Luggage",
        tag: "accessories:luggage"
      },
      {
        name: "Patio Umbrellas",
        tag: "accessories:patio-umbrellas"
      },
      {
        name: "Vases & Cachepots",
        tag: "accessories:vases"
      },
      {
        name: "Plants",
        tag: "accessories:plants"
      }
    ]
  },
  {
    name: "Office",
    tag: "office",
    pingpong: true,
    subcategories: [
      {
        name: "Office Electronics",
        tag: "office:electronics"
      },
      {
        name: "Office and Desk Dressing",
        tag: "office:office-and-desk-dressing"
      },
      {
        name: "Office Seating",
        tag: "office:seating"
      },
      {
        name: "Office Desks",
        tag: "office:desks"
      },
      {
        name: "Office Furniture",
        tag: "office:furniture"
      },
      {
        name: "Office Tables",
        tag: "office:tables"
      }
    ]
  },
  {
    name: "Gym/Outdoor/Fitness",
    tag: "gym-outdoor-fitness",
    pingpong: false,
    subcategories: [
      {
        name: "Bicycles",
        tag: "gym-outdoor-fitness:bicycles"
      },
      {
        name: "Balls",
        tag: "gym-outdoor-fitness:balls"
      },
      {
        name: "Miscellaneous",
        tag: "gym-outdoor-fitness:miscellaneous"
      }
    ]
  },
  {
    name: "Military",
    tag: "military",
    pingpong: true,
    subcategories: [ ]
  },
  {
    name: "Electronics",
    tag: "electronics",
    pingpong: true,
    subcategories: [
      {
        name: "Electronics - Miscellaneous (including Speakers)",
        tag: "electronics:electronics-miscellaneous-(including-speakers)"
      },
      {
        name: "Televisions",
        tag: "electronics:televisions"
      },
      {
        name: "Radios",
        tag: "electronics:radios"
      },
      {
        name: "Telephones",
        tag: "electronics:telephones"
      },
      {
        name: "Clocks",
        tag: "electronics:clocks"
      },
      {
        name: "Computers",
        tag: "electronics:computers"
      }
    ]
  },
  {
    name: "Desks",
    tag: "desk",
    pingpong: true,
    subcategories: []
  },
  {
    name: "Appliances",
    tag: "appliances",
    pingpong: false,
    subcategories: []
  },
  {
    name: "Kids",
    tag: "kids",
    pingpong: true,
    subcategories: []
  },
  {
    name: "Music",
    tag: "music",
    pingpong: true,
    subcategories: [
      {
        name: "Music - Instruments",
        tag: "music:instruments"
      },
      {
        name: "Music - Electronics",
        tag: "music:electronics"
      }
    ]
  },
  {
    name: "Medical",
    tag: "medical",
    pingpong: true,
    subcategories: [
      {
        name: "Medical - furniture",
        tag: "medical:furniture"
      },
      {
        name: "Medical - supplies",
        tag: "medical:supplies"
      },
      {
        name: "Medical - tools",
        tag: "medical:tools"
      }
    ]
  }
];
