import config from './config/default'

class JSONBin {
  static async saveWishlist (wishlistObj) {
    const data = {
      created_at: Date.now(),
      ...wishlistObj
    }
    const response = await fetch(`${config.jsonbin.rootUrl}/b`, {
      headers: { 'Content-type': 'application/json', 'secret-key': config.jsonbin.key , 'collection-id': config.jsonbin.collectionId, private: false },
      method: 'POST',
      body: JSON.stringify(data)
    })
    return response.json()
  }

  static async getWishlist (id) {
    const response = await fetch(`${config.jsonbin.rootUrl}/b/${id}/latest`, {
      headers: { 'secret-key': config.jsonbin.key }
    })
    return response.json()
  }

  static async updateWishlist (id, update) {
    const previousContent = await this.getWishlist(id)
    const newContent = {
      ...previousContent,
      ...update
    }
    const response = await fetch(`${config.jsonbin.rootUrl}/b/${id}`, {
      headers: { 'Content-type': 'application/json', 'secret-key': config.jsonbin.key , 'collection-id': config.jsonbin.collectionId, versioning: false },
      method: 'PUT',
      body: JSON.stringify(newContent)
    })
    return response.json()
  }
}

export default JSONBin
