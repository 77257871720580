import _ from 'lodash'
import categories from './categories'
import img from '../img/home.jpg'
import img2 from '../img/pingpong.jpeg'
import faviconB6 from '../img/b6-fav.ico'
import faviconPingPong from '../img/pp-fav.ico'

const cloudId = "dc7boufi5";
const site = process.env.REACT_APP_SITE || 'b6props'
const jsonstore = 'https://www.jsonstore.io/3ba91dfb6e41cf1253c984be031f8d0edf0091db438ac300a2efe1ffd4a0141b'
const jsonbin = {
  rootUrl: 'https://api.jsonbin.io',
  key: '$2b$10$Iqrn.On1OvNMUk1bOJc/De5vPVtbLwFPHSJZ3mV6ePYO7mJ30ZQue',
  collectionId: '5e9ad254435f5604bb43d823'
}
const config = {
  b6props: {
    title: 'B6PROPS',
    siteName: 'B<sup>6</sup> P<span style="font-size: x-small;">ROPS</span>',
    uniqueSellingProposition: 'P<span>rops</span> a<span>nd</span> f<span>urniture</span> f<span>or</span> s<span>et</span> d<span>ecoration</span>',
    homeImg: img,
    favicon: faviconB6,
    categories: categories,
    cloudId,
    address: {
      fullName: 'B6 Creative Investor Club',
      street: '66 Jozef Huysmanslaan',
      city: 'Beersel',
      zip: 1651,
      region: 'Vlaanderen',
      country: 'Belgium',
      googleMapsUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2523.479565998237!2d4.272451516594735!3d50.766683079520185!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNTDCsDQ2JzAwLjEiTiA0wrAxNicyOC43IkU!5e0!3m2!1sen!2sbe!4v1585742773944!5m2!1sen!2sbe"
    },
    contact: {
      email: 'info@b6props.com',
      phone: '+32 2 597 56 78',
      vat: 'BE.0552.842.491',
      bankaccountNumber: 'BE66 0018 4573 9743',
      instagram: 'https://www.instagram.com/b6props/',
      facebook: 'https://www.facebook.com/B6props-Cin%C3%A9-D%C3%A9co-Stock-2068142880132177/',
      pinterest: 'https://www.pinterest.com/b6props/'
    },
    gallery: {
      resourceFilter: r => _.has(r, 'context.custom.rental-price'),
      itemPricePath: 'context.custom.rental-price'
    },
    jsonstore,
    jsonbin
  },
  pingpong: {
    title: 'PingPong',
    siteName: 'PINGPONG',
    uniqueSellingProposition: 'Selected furniture, objects and curiosities',
    homeImg: img2,
    favicon: faviconPingPong,
    categories: categories.filter(c => c.pingpong),
    cloudId,
    address: {
      fullName: 'Ping Pong',
      street: '74 rue St-Georges',
      city: 'Ixelles',
      zip: 1050,
      region: 'Brussels',
      country: 'Belgium',
      googleMapsUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2520.7609828945506!2d4.365913215608527!3d50.81706697952766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3c4ef9479b6f3%3A0xa08130b6a8d015d5!2sSint-Jorisstraat%2074%2C%201050%20Elsene!5e0!3m2!1snl!2sbe!4v1576668751200!5m2!1snl!2sbe"
    },
    contact: {
      email: 'info@pingpong.house',
      phone: '+32 2 597 56 78',
      vat: 'BE 0552.842.491',
      bankaccountNumber: 'BE66 0018 4573 9743',
      instagram: 'https://www.instagram.com/b6props/'
    },
    gallery: {
      resourceFilter: r => _.has(r, 'context.custom.selling-price'),
      itemPricePath: 'context.custom.selling-price'
    },
    jsonstore,
    jsonbin
  }
}

export default {
  ...config[site]
}
