import React from 'react'
import { withRouter } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'
import config from './config/default'

const { address, contact } = config

export const Contact = (props) => {
  const style = {
    // WebkitFilter: 'grayscale(100%)',
    // filter: 'grayscale(100%)'
  }
  return <div className='flex flex--column justify-center align-center' style={ style }>
    <div style={{ width: '100%', padding: '0 2rem' }}>
      <iframe width="100%" height="450" title="contact" src={address.googleMapsUrl} frameBorder="0" style={{border: 0}} allowFullScreen=""></iframe>
      <div className="t-b mt30 mb30">{address.fullName}</div>
      <div className="flex contact-data">
        <span className="flex pl15 contact-devider mr50">
          <Icon name="map marker alternate" />
          <span>{address.street} {address.city},<br/>
          {address.region}, {address.zip}, {address.country}<br/></span>
        </span>
        <span className="pl15 contact-devider mr50">
          <Icon name='phone' />: {contact.phone}<br/>
          <Icon name='envelope' />: {contact.email}
        </span>
        <span className="pl15 contact-devider">
          IBAN: {contact.bankaccountNumber}<br/>
          VAT: {contact.vat}
        </span>
      </div>
    </div>
  </div>
}

export default withRouter(Contact)
