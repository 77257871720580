import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import { Icon } from 'semantic-ui-react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import _ from 'lodash'
import moment from 'moment'
import MasonryLayout from 'react-masonry-layout'
import { isMobile } from "react-device-detect"
import windowSize from 'react-window-size'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import { imgLinkFromPublicId, artCollectionURLForTag, getClosestInArr, convertRemToPixels } from './utils'
import config from './config/default'

// const picuturWidths = [1000, 1200, 1400, 1600, 2000]
const picuturWidths = [1600, 2000]

class Gallery extends Component {
  constructor (props) {
    super(props)
    this.state = {
      gallery: [],
      tag: props.match.params.tag,
      tags: props.tags,
      wishlistId: props.match.params.wishlistId,
      sharedWishListObj: undefined
    }

    this.ref = undefined
    this.handleAddToWishlist = props.handleAddToWishlist
    this.handleRemoveFromWishlist = props.handleRemoveFromWishlist
    this.doubleClickHandler = this.doubleClickHandler.bind(this)
    this.shareWishList = props.shareWishList
    this.getSharedWishList = props.getSharedWishList
  }

  static getDerivedStateFromProps (props, state) {
    return state.tag !== props.match.params.tag ? { tag: props.match.params.tag } : null
  }

  async fetchGallery (filter) {
    try {
      const tags = this.state.tags || [this.state.tag]
      const promises = tags.map(async t => {
        const response = await axios
          .get(artCollectionURLForTag(config.cloudId, t))
          .catch(err => { if (tags.length === 1) { throw err } }) // allow errors if is fetching multiple tags, do not allow for one tag fetch
        if (response) {
          // add additional info to all objects
          let data = response.data.resources
            .map(res => {
              res.tag = t // add tag to all objects
              res.timestamp = new Date(t.created_at).getTime() // add timestamp to all objects
              return res
            })
          if (filter) data = data.filter(filter)
          return data.sort((a, b) => a.timestamp - b.timestamp) // sort on timestamp
        }
        return []
      })
      const responses = await Promise.all(promises)
      let resources = []
      if (promises.length) {
        // for each category get latest 8 objects
        const first8s = responses.map(res => res.splice(0, 8))
        first8s.map(items => resources.push(...items))
        resources = _.shuffle(resources)
        // the rest of each category
        let rest = []
        responses.forEach(items => rest.push(...items))
        rest = _.shuffle(rest)
        // add the rest after all first 8 objects of each category
        resources.push(...rest)
      } else {
        responses.forEach(res => resources.push(...res))
      }
      this.setState({ gallery: resources })
    } catch (e) {
      this.props.history.push('/404')
      console.log(e)
    }
  }

  async setSharedWishlist () {
    if (this.props.match.params.wishlistId) {
      const sharedWishListObj = await this.props.getSharedWishListObj(this.props.match.params.wishlistId)
      this.setState({ sharedWishListObj })
    }
  }

  async componentDidMount () {
    if (this.props.match.params.wishlistId) {
      this.setSharedWishlist()
    } else if (!this.props.showWishlist) {
      this.fetchGallery(this.props.filter)
    }
    // console.log(this.ref)
    this.propagateWidth()
  }

  doubleClickHandler (url) {
    this.props.history.push(url)
  }

  clickHandler (publicId, url) {
    if (!isMobile || this.state.publicId === publicId) {
      this.setState({ publicId: null })
      this.props.history.push(url)
    } else {
      this.setState({ publicId })
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.match.params.wishlistId && prevProps.match.params.wishlistId !== this.state.wishlistId && !this.props.showWishlist) {
      this.setState({ sharedWishListObj: undefined })
      this.setSharedWishlist()
    } else if (prevProps.match.params.tag !== this.state.tag && !this.props.showWishlist) {
      this.fetchGallery(this.props.filter)
    }

    this.propagateWidth()
  }

  propagateWidth = () => {
    const { width } = this.getColumnSizes(this.props)
    if (width && this.props.setPictureWidth) {
      this.props.setPictureWidth(width)
    }
  }

  getColumnSizes (props) {
    const navbarPaddingRem = 1
    const navbarPaddingPixels = convertRemToPixels(navbarPaddingRem)
    const { windowWidth, isHome } = this.props
    const { tag } = this.props.match.params || {}
    const availableWidth = windowWidth - (2 * navbarPaddingPixels)
    // category
    let sizes = [
      { mq: 300, columns: 2, gutter: 10 },
      { mq: 750, columns: 5, gutter: 10 },
      { mq: 1000, columns: 5, gutter: 10 }
    ]
    // subcategory
    if (tag && tag.split(':').length === 2) {
      sizes = [
        { mq: 300, columns: 1, gutter: 10 },
        { mq: 750, columns: 4, gutter: 10 },
        { mq: 1000, columns: 4, gutter: 10 }
      ]
    }
    // home
    if (isHome) {
      sizes = [
        { mq: 300, columns: 2, gutter: 10 },
        { mq: 750, columns: 5, gutter: 10 },
        { mq: 1000, columns: 6, gutter: 10 },
        { mq: 1200, columns: 6, gutter: 10 },
        { mq: 1500, columns: 6, gutter: 10 },
        { mq: 1700, columns: 6, gutter: 10 },
        { mq: 1900, columns: 6, gutter: 10 }
      ]
    }
    let currentSize = sizes.find((s, idx) => {
      const nextItem = sizes[idx + 1]
      if (nextItem) {
        return windowWidth >= s.mq && windowWidth < nextItem.mq
      } else {
        return windowWidth >= s.mq
      }
    })
    currentSize = currentSize || sizes[sizes.length - 1]
    const nbColumns = currentSize.columns
    const gutterWidth = (nbColumns - 1) * parseInt(currentSize.gutter)
    const pictureWidth = (availableWidth - gutterWidth) / nbColumns
    const closestWidth = getClosestInArr(pictureWidth * 2, picuturWidths)
    // console.log(windowWidth, 2 * navbarPaddingPixels, gutterWidth, nbColumns, pictureWidth, closestWidth, currentSize)
    sizes.forEach(s => (s.mq += 'px'))
    sizes = sizes.filter(s => s === currentSize)
    return { sizes, width: pictureWidth, fetchWidth: closestWidth }
  }

  getPriceAndRest = (data) => {
    let price = _.get(data, config.gallery.itemPricePath)
    let rest = ''
    if (price) {
      const matches = price.match(/^(\d+)(.*)/)
      if (matches && matches.length === 3 && !isNaN(+matches[1])  ) {
        price = matches[1]
        rest = matches[2]
      }
    }
    return { price, rest }
  }

  render () {
    const { wishlist } = this.props
    const { sizes, width, fetchWidth } = this.getColumnSizes(this.props)
    const { openIdx, sharedWishListObj } = this.state
    let name
    let date
    // console.log('in render', sizes[0], width, fetchWidth)
    const showWishlist = this.props.showWishlist
    let gallery
    if (this.props.match.params.wishlistId) {
      gallery = sharedWishListObj ? sharedWishListObj.wishlist : []
      name = sharedWishListObj ? sharedWishListObj.name : 'Untitled wishlist'
      name = _.isEmpty(name) ? 'Untitled wishlist' : name
      date = sharedWishListObj ? sharedWishListObj.created_at : undefined
    } else if (!showWishlist) {
      gallery = this.state.gallery
    } else if (showWishlist) {
      gallery = wishlist
    }
    if (!gallery || gallery.length === 0) {
      return <div className="flex justify-center align-center t-white" style={{ height: '80vh' }}>Nothing found</div>
    }
    const masonryKey = width + '-' + gallery.length
    let imageCaption = ''
    if (openIdx !== false) {
      const d = gallery[openIdx]

      if (d && _.has(d, config.gallery.itemPricePath)) {
        const { price, rest } = this.getPriceAndRest(d)
        imageCaption = `${price}€${rest}`
      }
    }
    const returnedData =  [
      <MasonryLayout id={"masonry-layout"}
        ref={el => this.ref = el}
        className="gallery pb30"
        sizes={sizes}
        style={{width: '100% !important', maxWidth: '100% !important', margin: '0 auto'}}
        key={masonryKey}
      >
      {gallery.map((data, idx) => {
        const tag = data.tag
        const height = Math.round(data.height / (data.width / width))
        const inWishlist = wishlist.find(d => d.public_id === data.public_id)
        const { price, rest } = this.getPriceAndRest(data)
        return (
          <div className="card gallery-item" key={masonryKey + idx + data.public_id} style={{ height: height, width }}>
            <LazyLoadImage
              alt={data.public_id}
              height={height}
              src={imgLinkFromPublicId(config.cloudId, data.public_id, fetchWidth)} // use normal <img> attributes as props
              width={width} />
            <div className="card-body"onClick={(event) => { event.preventDefault(); event.stopPropagation(); this.setState({ openIdx: idx }) }}>
              <div className={"wishlist flex justify-end pointer " + (inWishlist ? 'in-wishlist' : '')}>
                {inWishlist
                ? (
                    <Icon onClick={(event) => { event.preventDefault(); event.stopPropagation(); this.handleRemoveFromWishlist({ ...data, tag })}} name='heart' />
                ) : (
                    <Icon onClick={(event) => { event.preventDefault(); event.stopPropagation(); this.handleAddToWishlist({ ...data, tag })}} name='heart outline' />
                )}
              </div>
              <div className="item-price">
                {_.has(data, config.gallery.itemPricePath) && <span className='ml5'>{price}<sup>€</sup>{rest}</span>}
              </div>
            </div>
          </div>
        )
      })}
        { openIdx !== false && gallery[openIdx] && <Lightbox
          mainSrcThumbnail={imgLinkFromPublicId(config.cloudId, gallery[openIdx].public_id, fetchWidth)}
          nextSrcThumbnail={imgLinkFromPublicId(config.cloudId, gallery[(openIdx + 1) % gallery.length].public_id, fetchWidth)}
          prevSrcThumbnail={imgLinkFromPublicId(config.cloudId, gallery[(openIdx + gallery.length - 1) % gallery.length].public_id, fetchWidth)}
          imageCaption= {imageCaption}
          nextSrc={imgLinkFromPublicId(config.cloudId, gallery[(openIdx + 1) % gallery.length].public_id, 2500)}
          prevSrc={imgLinkFromPublicId(config.cloudId, gallery[(openIdx + gallery.length - 1) % gallery.length].public_id, 2500)}
          mainSrc={imgLinkFromPublicId(config.cloudId, gallery[openIdx].public_id, 2500)}
          onMovePrevRequest={() =>
              this.setState({
                openIdx: (openIdx + gallery.length - 1) % gallery.length
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                openIdx: (openIdx + 1) % gallery.length
              })
            }
          onCloseRequest={() => this.setState({ openIdx: false })} /> }
      </MasonryLayout>
    ]
    if (showWishlist) {
      returnedData.push(<ShareButton shareWishList={this.shareWishList}/>)
    }
    if (name) {
      returnedData.unshift(<div className="flex t-c t-20 justify-center mb15 p15"><span>{name} created on {moment(date).format("dddd, MMMM Do YYYY, h:mm:ss a")}</span></div>)
    }
    return returnedData
  }
}

export default withRouter(windowSize(Gallery))


const ShareButton = (props) => {
  return [
    <svg>
      <defs>
        <symbol id="share-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-share"><path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path><polyline points="16 6 12 2 8 6"></polyline><line x1="12" y1="2" x2="12" y2="15"></line></symbol>
      </defs>
    </svg>,
    <button className="share-button" type="button" title="Share wishlist" onClick={ (event) => { event.preventDefault(); event.stopPropagation(); props.shareWishList(); } } >
      <svg>
        <use href="#share-icon"></use>
      </svg>
      {/* <span>Share</span> */}
    </button>
  ]
}
