import axios from 'axios'

function artCollectionURLForTag(cloudId, tag) {
  return `https://res.cloudinary.com/${cloudId}/image/list/${tag}.json`
}

function imgLinkFromPublicId (cloudId, publicId, width) {
  return `https://res.cloudinary.com/${cloudId}/image/upload/c_limit,dpr_auto${width ? `,w_${width}` : ''}/v1/${publicId}`
}

function getClosestInArr (needle, haystack) {
  return haystack.reduce((prev, curr) => (Math.abs(curr - needle) < Math.abs(prev - needle) ? curr : prev))
}

function convertRemToPixels (rem) {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
}

function convertPixelsToRem (pixels) {
  return pixels / parseFloat(getComputedStyle(document.documentElement).fontSize)
}

export const getTagResources = async (tag, cloudId) => {
  const response = await axios.get(artCollectionURLForTag(cloudId, tag))
  if (response && response.data.resources) {
    return response.data.resources
  }
  return null
}
try {

} catch (e) {
  console.log(e)
}

export {
  artCollectionURLForTag,
  imgLinkFromPublicId,
  getClosestInArr,
  convertRemToPixels,
  convertPixelsToRem
}
