import './App.scss'
import 'react-toastify/dist/ReactToastify.css'
import React, { Component, Fragment } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import * as _ from 'lodash'
import config from './config/default'
import Gallery from './Gallery'
import Item from './Item'
import { default as Navbar2 } from './Navbar'
import Contact from './Contact'
import Favicon from 'react-favicon'
import Modal from 'react-bootstrap/Modal'
import { toast } from 'react-toastify'
// import Button from 'react-bootstrap/Button'
import JSONBin from './jsonbin'
import { CopyToClipboard } from 'react-copy-to-clipboard'


import { getTagResources } from './utils'

toast.configure()

class App extends Component {
  constructor(props) {
    super(props)
    const wishlist = JSON.parse(localStorage.getItem('wishlist')) || []
    this.state = {
      categories: config.categories,
      wishlist,
      modalShow: false
    }
  }

  addToWishlist = item => {
    const wishlist = this.state.wishlist
    const itemInArray = this.state.wishlist.find(i => i.public_id === item.public_id)
    if (!itemInArray) wishlist.push(item)
    localStorage.setItem('wishlist', JSON.stringify(wishlist))
    this.setState({ wishlist })
  }

  removeFromWishlist = item => {
    const wishlist = this.state.wishlist.filter(d => d.public_id !== item.public_id )
    localStorage.setItem('wishlist', JSON.stringify(wishlist))
    this.setState({ wishlist })
  }

  shareWishList = async () => {
    const response = await JSONBin.saveWishlist({ wishlist:this.state.wishlist })
    console.log(response)
    this.setState({ modalShow: true, wishlistShareUrl: `${window.location.origin}/shared-wishlist/${response.id}`, wishlistId: response.id })
  }

  setWishlistName = async (id, name) => {
    await JSONBin.updateWishlist(id, { name })
  }

  getSharedWishList = async (id) => {
    const response = await this.getSharedWishListObj(id)
    return response.wishlist
  }

  getSharedWishListObj = async (id) => {
    return await JSONBin.getWishlist(id)
  }

  setPictureWidth = width => {
    if (!this.state.pictureWidth || this.state.pictureWidth !== width) {
      this.setState({ pictureWidth: width })
    }
  }

  async fetchallCategoryNumbers (categories) {
    const promises = []
    for (let category of categories) {
      const promise = getTagResources(category.tag, config.cloudId).then(resources => {
        category.nbItems = 0
        if (resources) category.nbItems = resources.length
        if (category.nbItems && !_.isEmpty(category.subcategories)) this.fetchallCategoryNumbers(category.subcategories)
        return categories
      }).catch(e => {
        // console.error(e)
      })
      promises.push(promise)
    }
    await Promise.all(promises)
    return categories
  }

  componentDidMount () {
    document.title = config.title
    this.fetchallCategoryNumbers(this.state.categories).then(categories => this.setState({ categories }))
  }

  render () {
    const {
      pictureWidth,
      categories,
      wishlist,
      modalShow,
      wishlistShareUrl,
      wishlistId
    } = this.state
    return (
      <div className="main container-fluid p0" >
      <MyVerticallyCenteredModal
          show={modalShow}
          value={wishlistShareUrl}
          onHide={() => this.setState({ modalShow: false })}
          setWishlistName={(name) => this.setWishlistName(wishlistId, name)}
        />
        <Favicon url={config.favicon} />
        <Router>
          <Switch>
            <Route path="/404">
              <Navbar2 {...{ wishlist, pictureWidth, categories }} />
              <div className="row">
                {/* {navWrapper} */}
                <div className="content col-md-9">
                  <h1>Not Found</h1>
                </div>
              </div>
            </Route>
            <Route path={`/categories/:tag`}
              render={routeProps =>
                <Fragment>
                  <Navbar2 {...{ wishlist, pictureWidth, categories }} />
                  <div className="row">
                    {/* {navWrapper} */}
                    <div className="content col-md-12 p0">
                      <Gallery key={routeProps.match.params.tag} {...routeProps} {...{
                          wishlist,
                          handleAddToWishlist: this.addToWishlist,
                          handleRemoveFromWishlist: this.removeFromWishlist
                        }}
                      />
                    </div>
                  </div>
                </Fragment>}
            />
            <Redirect exact from="/categories" to="/categories/seating" />
            <Route path="/contact">
              <Navbar2 {...{ wishlist, pictureWidth, categories }} />
              <div className="row">
                <div className="content col-md-12 p0">
                  <Contact />
                </div>
              </div>
            </Route>
            <Route path="/wishlist/:item_id" render={routeProps =>
              <Fragment>
                <Navbar2 {...{ wishlist, pictureWidth, categories }} />
                <Item {...routeProps} {...{
                  isWishlist: true,
                  wishlist,
                  handleAddToWishlist: this.addToWishlist,
                  handleRemoveFromWishlist: this.removeFromWishlist
                }}/>
              </Fragment>} />
            <Route path="/wishlist" render={routeProps =>
              <Fragment>
                <Navbar2 {...{ wishlist, pictureWidth, categories }} />
                <div className="row">
                  <div className="content col-md-12 p0">
                  <Gallery {...routeProps} {...{
                      showWishlist: true,
                      wishlist,
                      handleAddToWishlist: this.addToWishlist,
                      handleRemoveFromWishlist: this.removeFromWishlist,
                      setPictureWidth: this.setPictureWidth,
                      shareWishList: this.shareWishList
                    }}
                  />
                  </div>
                </div>
              </Fragment>} />
            <Route path="/shared-wishlist/:wishlistId" render={routeProps =>
              <Fragment>
                <Navbar2 {...{ wishlist, pictureWidth, categories }} />
                <div className="row">
                  <div className="content col-md-12 p0">
                  <Gallery {...routeProps} {...{
                      wishlist,
                      showWishlist: false,
                      handleAddToWishlist: this.addToWishlist,
                      handleRemoveFromWishlist: this.removeFromWishlist,
                      setPictureWidth: this.setPictureWidth,
                      getSharedWishList: this.getSharedWishList,
                      getSharedWishListObj: this.getSharedWishListObj,
                      shareWishList: this.shareWishList
                    }}
                  />
                  </div>
                </div>
              </Fragment>} />
            <Route
              path={`/:tag/:item_id`}
              render={routeProps => <Fragment>
                <Navbar2 {...{ wishlist, pictureWidth, categories }} />
                <Item {...routeProps} {...{
                  wishlist,
                  handleAddToWishlist: this.addToWishlist,
                  handleRemoveFromWishlist: this.removeFromWishlist,
                  setPictureWidth: this.setPictureWidth
                }}/>
              </Fragment>}
            />
            <Route path="/" render={routeProps => <Fragment>
                <Navbar2 {...{ wishlist, pictureWidth, categories }} />
                <div className="row">
                  <div className="content col-md-12 p0">
                    <Gallery key="home" {...routeProps}
                        {...{
                        isHome: true,
                        tags: config.categories.map(c => c.tag),
                        filter: (res) => !res.public_id.includes('lamps-floor') && !res.public_id.includes('Coat-rack'),
                        wishlist,
                        handleAddToWishlist: this.addToWishlist,
                        handleRemoveFromWishlist: this.removeFromWishlist,
                        setPictureWidth: this.setPictureWidth
                      }}
                    />
                  </div>
                </div>
              </Fragment>} />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App


class MyVerticallyCenteredModal extends React.Component {
  state = {
    copied: false,
    name: ''
  }
  render () {
    const setWishlistName = () => {
      const name = this.state.name
      if (!_.isEmpty(name) && this.props.setWishlistName) this.props.setWishlistName(name)
    }
    const hide = () => {
      setWishlistName()
      this.setState({ copied: false, name: '' })
      this.props.onHide()
    }
    const propsToPass = {}
    Object.keys(this.props).filter(k => k!== 'setWishlistName').forEach((key, i) => propsToPass[key] = this.props[key])
    return (
      <Modal
        {...propsToPass}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="model-share"
        onHide={hide}
      >
        <Modal.Header className="flex justify-center flex--column t-c align-center">
          <Modal.Title id="contained-modal-title-vcenter">
            Share this wishlist
          </Modal.Title>
          <div>You can save and share as many lists as you like. Each list has a URL</div>
        </Modal.Header>
        <Modal.Body>
          <input className="ml10" type="text" name="name" placeholder="My title" onKeyUp={(e) => this.setState({ name: e.target.value })} />
          <CopyToClipboard text={this.props.value} onCopy={() => {
            toast.success('Link copied', {
              autoClose: 2500,
              style: { fontFamily: 'GravurCondensed !important' }
            })
            setWishlistName()
            this.setState({ copied: true })
            hide()
          }}>
            <div className="share-link">
              <input type='text' className="share-pen-url" defaultValue={ this.props.value }></input>
              <button className={"share-copy-link icofont-before " + (this.state.copied ? "icofont-check" : "")}>Copy Link</button>
            </div>
          </CopyToClipboard>

        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="dark" onClick={this.props.onHide}>Close</Button>
        </Modal.Footer> */}
      </Modal>
    )
  }
}
