
import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import * as _ from 'lodash'
import { convertPixelsToRem } from './utils'
import img from './assets/B6_banner.svg'

import config from './config/default'

const categorySortFn = (a, b) => {
  if (a.nbItems && b.nbItems) return a.name.localeCompare(b.name)
  if (a.nbItems && !b.nbItems) return -1
  if (!a.nbItems && b.nbItems) return 1
  if (!a.nbItems && !b.nbItems) return a.name.localeCompare(b.name)
}

const SHOOTING_IN_PROGRESS_TXT = 'shooting in progress'

class CategoryMenuItem extends Component {
  constructor (props) {
    super(props)
    this.ref = undefined
    this.state = {
      categoryOpen: false
    }
  }


  render () {
    const { category, level = 0, activeCategory } = this.props
    const { nbItems } = category || this.state
    const { categoryOpen } = this.state
    let classes = activeCategory === category.tag ? 't-b' : 't-fwu'
    const badgeLabel = nbItems && nbItems > 0 ? nbItems : ''
    const badge = badgeLabel ? '(' + badgeLabel + ')' : ''

    const onClick = (e) => {
      console.log(e.target, this.ref)
      if (e.target === this.ref) e.stopPropagation()
      this.setState({ categoryOpen: !categoryOpen })
    }

    const title = <span onClick={onClick} ref={el => this.ref = el}>{category.name} {badge}</span>
    if (_.isEmpty(category.subcategories) || !nbItems) {
      return <Link to={`/categories/${category.tag}`} href={`/categories/${category.tag}`} key={category.tag} className={classes + (!nbItems ? ' link-disabled' : '')} >
        {category.name} {badge}
      </Link>
    } else {
      let isFirst = true
      category.subcategories.sort(categorySortFn)
      return <div className="dropdown-submenu t--lowercase gallery-navbar-link pointer-only" title={title} key={category.tag}>
        {title}
        {categoryOpen && <div className="dropdown-submenu-wrapper ml5">
          <Link to={`/categories/${category.tag}`} href={`/categories/${category.tag}`} key={category.tag} className='t-fwu' >
            All {category.name} {badge}
          </Link>
          {category.subcategories.map(subcategory => {
            const content = [<CategoryMenuItem level={level + 1} key={subcategory.tag} category={subcategory} activeCategory={activeCategory} />]
            if (!subcategory.nbItems && isFirst) {
              isFirst = false
              content.unshift(<Link to={`/categories/${category.tag}`} key={category.tag + '_comming_soon'} className="link-disabled comming-soon t-fwu">
                {SHOOTING_IN_PROGRESS_TXT}
              </Link>)
            }
            return content
          })}
        </div> }
      </div>
    }
  }
}


export class Navbar extends Component {
  constructor (props) {
    super(props)
    this.ref = undefined
    this.state = {
      padding: 1,
      show: true,
      scrollPos: document.body.getBoundingClientRect().top,
      categoriesOpen: false
    }
    this.handleScroll = this.handleScroll.bind(this)
    this.clickOutside = this.clickOutside.bind(this)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
    document.removeEventListener('click', this.clickOutside)
  }

  componentDidMount () {
    // this.adjustPadding()
    window.addEventListener("scroll", this.handleScroll)
    document.addEventListener('click', this.clickOutside)
  }

  clickOutside (event) {
    const isClickInside = this.ref.contains(event.target)
    if (!isClickInside) this.setState({ categoriesOpen: false })
  }

  componentDidUpdate () {
    // this.adjustPadding()
  }
  handleScroll () {
    const { scrollPos } = this.state
    const currentScrollTop = document.body.getBoundingClientRect().top

    if (this.state.ignoreNextHandleScroll) {
      this.setState({ scrollPos: currentScrollTop, ignoreNextHandleScroll: false })
    }
    // Set the state of hidden depending on scroll position
    // We only change the state if it needs to be changed
    else if (this.state.show && currentScrollTop < scrollPos && ((scrollPos - currentScrollTop) > 30 && currentScrollTop < -300)) {
      this.setState({ show: false, scrollPos: currentScrollTop, ignoreNextHandleScroll: true });
    } else if(!this.state.show && currentScrollTop > scrollPos && ((currentScrollTop - scrollPos) > 30 || currentScrollTop === 0)) {
      this.setState({ show: true, scrollPos: currentScrollTop, ignoreNextHandleScroll: true })
    } else {
      this.setState({ scrollPos: currentScrollTop })
    }
  }

  adjustPadding = () => {
    const navbarWidth = _.get(this, 'ref.offsetWidth')
    const masonryEl = document.getElementById('masonry-layout') || {}
    let masoneryContainerWidth = masonryEl.offsetWidth || null
    if (navbarWidth && masoneryContainerWidth) {
      const padding = navbarWidth - masoneryContainerWidth
      const paddingRem = parseFloat(convertPixelsToRem(padding / 2).toFixed(2))
      if (this.state.padding !== paddingRem) this.setState({ padding: paddingRem })
    }
  }

  getBreadcrumbUrl (item, idx, allItems) {
    if (item === 'categories') {
      return '/'
    } else if (idx === 0) {
      return `/${item}`
    } else if (idx === 1) {
      return `/${allItems[0]}/${item}`
    } else if (idx === 2) {
      return `/${allItems[0]}/${allItems[0]}:${item}`
    }
  }

  render () {
    let {
      wishlist,
      categories
    } = this.props
    const { categoriesOpen } = this.state
    const { match } = this.props
    let crumbs = []
    if (match && match.url) {
      crumbs = match.url.split('/').filter(i => i).map(i => i.split(':')).flatMap(i => i)
      if (crumbs[0] !== 'categories') crumbs = []
    }
    categories.sort(categorySortFn)
    const { padding } = this.state
    const { tag } = this.props.match.params
    const style = {
      paddingLeft: padding + 'rem',
      paddingRight: padding + 'rem'
    }
    let isFirst = true
    // const style = {}
    return (
      <div className="navbar_wrap mb15" style={style} ref={el => this.ref = el}>
        <div className="logo">
          <Link to='/'>
            <img src={img} alt='b6slug' />
          </Link>
        </div>
        <div className="flex justify-between">
          <div className="desktop-menu pt5">
            <div className={'t--lowercase pointer-only ' + (match.path.startsWith('/categories') ? 'active-link' : 't-b')} onClick={() => this.setState({ categoriesOpen: !categoriesOpen })}>
              Categories
              {categoriesOpen && <div className='link-dropdown'>
                 {categories.map(it => {
                  const content = [<CategoryMenuItem className="dropdown-submenu t--lowercase gallery-navbar-link" category={it} key={it.tag} activeCategory={tag} />]
                  if (!it.nbItems && isFirst) {
                    isFirst = false
                    content.unshift(<div as={Link} to={`/categories/${it.tag}`} key={it.tag + '_comming_soon'} className="link-disabled comming-soon t-fwu">
                      {SHOOTING_IN_PROGRESS_TXT}
                    </div>)
                 }
                 return content
                })}
              </div>}
            </div>
            <Link as={Link} className={'with-separator t--lowercase ' + (match.path.startsWith('/wishlist') ? 'active-link' : 't-b')} to="/wishlist" href="/wishlist">
              Wishlist {wishlist.length > 0 ? `(${wishlist.length})` : ''}
            </Link>
            <Link as={Link} className={'with-separator t--lowercase ' + (match.path.startsWith('/contact') ? 'active-link' : 't-b')} to="/contact" href="/contact">Contact</Link>
          </div>
            <div className="flex ml10 align-center pt5">
              { crumbs.length > 0 && crumbs.map((c, idx, arr) => <Link key={`${c}-${idx}`} class='breadcrumb-item hide-max-sm' to={this.getBreadcrumbUrl(c, idx, arr)}>{c}</Link>)}
              <a className="t--lowercase mr0 pr0 ml10" href={config.contact.instagram} target="_blank" rel='noopener noreferrer'>
                <span className="icofont-before icofont-instagram icofont-nm pl1"></span>
              </a>
              <a className="t--lowercase pl5 mr0 pr0" href={config.contact.facebook} target="_blank" rel='noopener noreferrer'>
                <span className="icofont-before icofont-facebook icofont-nm"></span>
              </a>
              <a className="t--lowercase pl5 mr0 pr0" href={config.contact.pinterest} target="_blank" rel='noopener noreferrer'>
                <span className="icofont-before icofont-pinterest icofont-nm"></span>
              </a>
          </div>
        </div>
     </div>
    )
  }
}

export default withRouter(Navbar)
